<template>
  <transition-group
    tag="ul"
    class="
      w-14
      h-8
      rounded-full
      flex
      items-center
      px-1
      ring-2 ring-orange
      transition-colors
      duration-200
      cursor-pointer
    "
    :class="{
      'justify-end bg-orange': checked,
      'justify-start bg-white': !checked,
    }"
    @click="checked = !checked"
    name="scale-up"
  >
    <li
      class="w-6 h-6 rounded-full"
      :class="{ 'bg-white': checked, 'bg-orange': !checked }"
      key="toggle"
    ></li>
  </transition-group>
</template>

<script>
export default {
  props: {
    active: Boolean
  },
  data() {
    return {
      checked: this.active || false,
    };
  },
  emits: ["switch"],
  watch: {
    checked: function () {
      this.$emit("switch", this.checked);
    },
  },
};
</script>
