<template>
   
        <modal :active="shareModal" :key="modalKey">
            <div class="
          mx-auto
          flex
          items-center
          justify-center
          h-12
          w-12
          rounded-full
          bg-orange-tint
        ">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" class="h-6 w-6 text-orange fill-current"
                    viewBox="0 0 30 30">
                    <path
                        d="M 23 3 A 4 4 0 0 0 19 7 A 4 4 0 0 0 19.09375 7.8359375 L 10.011719 12.376953 A 4 4 0 0 0 7 11 A 4 4 0 0 0 3 15 A 4 4 0 0 0 7 19 A 4 4 0 0 0 10.013672 17.625 L 19.089844 22.164062 A 4 4 0 0 0 19 23 A 4 4 0 0 0 23 27 A 4 4 0 0 0 27 23 A 4 4 0 0 0 23 19 A 4 4 0 0 0 19.986328 20.375 L 10.910156 15.835938 A 4 4 0 0 0 11 15 A 4 4 0 0 0 10.90625 14.166016 L 19.988281 9.625 A 4 4 0 0 0 23 11 A 4 4 0 0 0 27 7 A 4 4 0 0 0 23 3 z">
                    </path>
                </svg>
            </div>
            <div class="mt-3 text-center sm:mt-5">
                <h3 class="text-lg leading-6 font-bold text-gray-900">
                    Share this post!
                </h3>
            </div>

            <div class="relative py-4">
                <Button :text="copied ? 'Copied!' : 'Copy post link'" small type="primary" class="mx-auto"
                    @click="copyURL" />
            </div>
            <p class="font-bold uppercase text-center -my-1">or</p>
            <p class="text-xs font-bold text-center pt-4">Share on social media</p>
            <div class="mt-1 flex justify-between">
                <a :href="`https://wa.me/?text=Check%20out%20this%20post%20on%20Tutoruu%21%0A%0Ahttps%3A%2F%2Fstudents.tutoruu.com/feed/post/${post.post_id}`"
                    target="_blank" class="w-24 h-20 bg-white rounded-3xl hover:shadow-lg transition">
                    <img class="w-12 h-12 mx-auto" src="https://img.icons8.com/color/480/000000/whatsapp--v1.png" />
                    <p class="font-bold text-xs text-center capitalize">Whatsapp</p>
                </a>
                <a :href="`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fstudents.tutoruu.com/feed/post/${post.post_id}&amp;src=sdkpreparse`"
                    target="_blank" class="w-24 h-20 bg-white rounded-3xl hover:shadow-lg transition">
                    <img class="w-12 h-12 mx-auto" src="https://img.icons8.com/fluency/240/000000/facebook.png" />
                    <p class="font-bold text-xs text-center capitalize">Facebook</p>
                </a>
                <a :href="`https://twitter.com/intent/tweet?text=Check%20out%20this%20post%20on%20Tutoruu%21%0A%0Astudents.tutoruu.com/feed/post/${post.post_id}`"
                    class="w-24 h-20 bg-white rounded-3xl hover:shadow-lg transition" target="_blank">
                    <img class="w-12 h-12 mx-auto" src="https://img.icons8.com/color/480/000000/twitter--v1.png" />
                    <p class="font-bold text-xs text-center capitalize">Twitter</p>
                </a>
            </div>
        </modal>
    
</template>

<script>
import { mapState } from "vuex";
import Button from "../button.vue";
import modal from "../modal.vue";
import tag from "../tag.vue";

export default {
    components: { modal, tag, Button },
    props: {
    post: {
      type: Object,
      default: {},
    },
    shareModal: {
      type: Boolean,
      default: false,  
    }
  },
    computed: {
        ...mapState(["user"]),
    },
    methods: {
        copyURL() {
            navigator.clipboard.writeText(
                `https://students.tutoruu.com/feed/post/${this.post.post_id}`
            );
            this.copied = true;
        },
    },
    data() {
        return {
            modalKey: 0,
            copied: false,
        };
    },
};
</script>