import store from "../../../store";

export const savePost = async (id) => {
  return await store.state.feedAPI.post('Post/Save',{post_id :id});
}

export const removeSavedPost = async (id) => {
  return await store.state.feedAPI.post('Post/Remove/Saved',{post_id :id})
}

export const reportPost = async (id) => {
  return await store.state.feedAPI.post('Post/Report',{post_id :id})
}

export const likeOrDislikePost = async (likeId,postId) => {
  return await store.state.feedAPI.post('Post/Add/Reaction',{like_id: likeId ,post_id :postId})
}

export const deleteLikeOrDislikePost = async (post_id, like_id) => {
  return await store.state.feedAPI.post('Post/Remove/Reaction',{ post_id, like_id })
}

