<template>
  <div class="
      transition
      duration-150
      ease-out
      cursor-pointer
      mr-1
      mb-1
      
      font-bold
      rounded-full
      
      bg-primaryLight 
      hover:bg-opacity-50
      focus:ring-2 focus:ring-orange focus:ring-offset-2
      max-w-min
      inline-block
    " :class="{ 'px-3.5 py-2 text-xs': !small, 'px-1 h-4': small, 'text-black': color, 'text-orange': !color }"
    :style="small ? 'font-size: 10px!important;' : ''">
    <span v-if="text=='' " class="whitespace-nowrap">{{ number }} EGP</span>
    <span v-if="text!=''" class="whitespace-nowrap">{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    number:Number
    ,
    text: {
      type: String,
      default:''
    }
    ,
    small: Boolean,
    color: Boolean,
  },
};
</script>