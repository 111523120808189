import store from "../../../store";
import { TutoruuAPI } from ".";

import { getFeedUser } from "../Feeds/User";

import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
} from "firebase/storage";

/**
 * @description Updates the current user in the database
 * @param {*} userData
 * @returns Updated User
 */
export const updateCurrentUser = async (userData) => {
    // grab the id from the user
    const { _id } = store.state.user;

    try {
        // update user in database
        const user = await TutoruuAPI.patch(`user/${_id}/fields`, {
            fields: userData,
        });

        // update state user
        store.commit("updateUser", userData);

        // fire notification
        store.commit("fireToast", {
            text: `Profile updated`,
            type: "confirmation",
        });
        
        // return the new user
        return user;
    } catch (e) {
        console.error(e);
        store.commit("fireToast", {
            text: `Something went wrong. Try again in a few minutes`,
            type: "error",
        });
    }
};

export const getUser = async (username) => {
    const { user } = await TutoruuAPI.get(`user/username/${username}`);

    // if (getFeed && !!user)
    //   user.feed_user = await getFeedUser(user.feed_user_id);

    return user;
}

export const uploadProfilePicture = async (event) => {
    const file = event.target.files[0];
    const myFile = file;
    try {
        if (file && file.name) {
            store.state.imageLoading = true;
            const fr = new FileReader();
            fr.readAsDataURL(file);

            fr.addEventListener("load", () => {
                // this is to load image on the UI
                // .. not related to file upload :)
                return fr.result;
            });
            const imgData = new FormData();
            imgData.append("image", myFile);
            const filePath = `images/users/${store.state.user.username
                }/profile_picture/${Date.now()}-${file.name}`;
            const metadata = { contentType: myFile.type };
            const storage = getStorage();
            const profileImageRef = ref(storage, filePath);
            const uploadTask = uploadBytesResumable(
                profileImageRef,
                myFile,
                metadata
            );
            uploadTask.on(
                "state_changed",
                (snapshot) => {},
                (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case "storage/unauthorized":
                            // User doesn't have permission to access the object
                            break;
                        case "storage/canceled":
                            // User canceled the upload
                            break;
                        case "storage/unknown":
                            // Unknown error occurred, inspect error.serverResponse
                            break;
                    }
                },
                () => {
                    // Upload completed successfully, now we can get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        store.state.user.img_url = downloadURL;
                        store.state.imageLoading = false;
                    });
                }
            );
        }
    } catch (e) {
        console.error(e);
    }
};