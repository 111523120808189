<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <teleport to="body">
    <transition name="fade" appear>
      <div v-if="open">
        <div class="fixed z-10 inset-0 overflow-y-auto">
          <div
            class="
              flex
              items-end
              justify-center
              min-h-screen
              pt-4
              px-4
              pb-20
              text-center
              sm:block sm:p-0
            "
          >
            <div
              class="
                fixed
                inset-0
                bg-gray-500 bg-opacity-75
                transition-opacity
                h-screen
                flex
                items-center
                justify-center
              "
            >
              <transition name="scale-up" appear class="relative">
                <div
                  v-if="open"
                  class="
                    mx-4
                    bg-white
                    rounded-3xl
                    px-4
                    pt-5
                    pb-4
                    text-left
                    shadow-xl
                    sm:my-8 sm:max-w-sm sm:w-full sm:p-6
                  "
                >
                  <div
                    class="
                      absolute
                      z-50
                      right-2
                      top-2
                      cursor-pointer 
                      hover:shadow-lg
                      transition
                      w-8
                      h-8
                      rounded-full
                      flex
                      items-center
                      justify-center
                    "
                    @click="close"
                  >
                    <p class="text-2xl font-bold text-center">x</p>
                  </div>
                  <div class="pt-6">
                      <slot></slot>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
export default {
  emits: ["close"],
  props: {
    active: Boolean,
  },
  data() {
    return {
      open: this.active,
    };
  },
  methods: {
    close() {
      this.open = false;
      this.$emit("close");
    },
  },
};
</script>