<template>
    <div class="flex flex-col rounded-3xl h-44 justify-center items-center border w-full space-y-3 mt-2 p-3">
        <div class="flex flex-col items-center justify-center">
            <vue-feather v-if="!becomeAtutor" :type="iconType" size="56" stroke="orange"></vue-feather>
            <svg v-if="becomeAtutor" width="65" height="72" viewBox="0 0 65 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.0876 48.7338C18.7051 44.3815 16.5148 41.5193 15.5737 38.2872C15.5358 38.1622 15.4991 38.0318 15.46 37.8862C15.7971 37.5635 16.0163 37.1373 16.0829 36.6755C16.1494 36.2136 16.0594 35.7427 15.8271 35.338C15.6333 35.0323 15.3544 34.7898 15.0249 34.64C15.1293 33.0164 15.952 31.7184 18.5661 32.1648C20.6007 32.5152 24.6473 38.8425 25.1648 41.4988C46.5854 20.0124 60.3986 17.7469 60.3986 17.7469C57.3802 17.0126 55.0347 13.6715 52.3555 11.5304C53.9249 9.47988 55.6274 7.53479 57.4522 5.70767C59.6316 3.91498 61.9046 2.23896 64.2614 0.686808C35.2776 -0.271211 19.8294 21.0171 0.678179 36.0631C0.918638 36.5763 1.1495 37.093 1.39421 37.613C5.28076 36.3943 9.15178 35.1784 13.0071 33.9652C13.2138 34.1335 13.4104 34.3008 13.6106 34.4662C13.3267 34.5288 13.0553 34.6387 12.8077 34.791C12.3062 35.0723 11.9364 35.5407 11.7791 36.0938C11.6218 36.6469 11.6897 37.2397 11.9681 37.7428C12.0557 37.8792 12.1615 38.003 12.2828 38.1105C11.7768 38.8281 11.5064 39.6852 11.509 40.5632C12.4751 49.8382 13.867 59.0691 15.3303 68.286C15.4107 68.8537 15.5799 69.4052 15.8322 69.9201C15.9143 70.0924 16.0158 70.2548 16.1346 70.4041L17.5067 71.145L18.7133 70.0533L19.537 71.628C20.1635 71.4852 20.7653 71.2499 21.3225 70.93L23.108 69.1742C25.1585 62.3611 24.4323 55.0136 21.0876 48.7338Z" fill="#FF8303"/>
</svg>


        </div>
        <div>{{ text }} </div>
        <div class="h-8 flex flex-col items-center justify-center ">
      <Button v-if="showButton"  @click="this.$emit('clicked')" class="font-poppins" color="primary" size="small" shape="full">{{ buttonText }}</Button>
    </div>
    </div>
</template>

<script>

import { link } from "fs";
import Button from "../../components/Feed/Button.vue";
export default {
    emits: ["clicked"],
    components: { Button, },
    props: {
        text: {
            type: String,
            default: "",
        },
        iconType: {
            type: String,
            default: "",
        },
        showButton:{
            type: Boolean,
            default: false,
        },
        becomeAtutor:{
            type: Boolean,
            default: false,
        },
        buttonText:{
            type: String,
            default: "Post",
        },
    },
    methods: {
    }
}
</script>

