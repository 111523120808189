<template>
  <modal :active="showModal">
    <div class="">
      <Avatar :user="userInState" class="items-center" name size="small" />
      <textarea class="
          text-base
          font-normal
          p-2.5
          pr-4
          resize-none
          text-gray-700
          bg-white bg-clip-padding
          border border-solid border-gray-400
          rounded
          my-2
          w-80
          h-20
          focus:border-orange focus:outline-none
        " placeholder="Leave a reply..." v-model="text "></textarea>
    </div>
    <Button @click="LeaveComment" size="small" shape="full" class="pt-3">Reply</Button>
  </modal>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Avatar from "./Feed/Avatar.vue";
import modal from "./modal.vue";
import Button from "./Feed/Button.vue";
import { createComment, editComment } from "../helpers/API/Feeds/Comment";
import { stringLength } from "@firebase/util";
export default {
  components: { Avatar, modal, Button },
  props: {
    postId: {
      type: Number,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    text:{
      type:String,
       default:''
    },
    Edited: {
      type: Boolean,
      default: false,
    },
    commentId: {
      type: Number,
      required:true,
    }
    
  },
  data() {
    return {
      userInState:null,
      firstname: "",
      lastname: "",
      university: "",
      showModal: true,
      update: 0,
    };
  },
  created(){
  this.setUser();
  },
  
  methods: {
     ...mapMutations(["fireToast"]),
     ...mapActions(["completeQuest"]),
     setUser() {
      this.userInState = this.$store.state.user;
      // splitting name into firstname and lastname
    },
    async LeaveComment() {
      if(!this.Edited){
        const text = this.text.replace(/\s/g, '');
       if (text.length === 0) return this.fireToast({
         text: `Please write a message`,
         type: "error",
       });
       await createComment({
         post_id: this.postId,
         text: this.text,
       }).then(this.completeQuest("Leave 5 comments"));
       this.showModal = false;
       location.reload();
      }
      else{
        const text = this.text.replace(/\s/g, '');
       if (text.length === 0) return this.fireToast({
         text: `Please write a message`,
         type: "error",
       });
       await editComment({
         post_id: this.postId,
         text: this.text,
         comment_id: this.commentId
       });
       this.showModal = false;
       location.reload();
      }
    },
  },
};
</script>

