<template>
  <button
    v-if="!removed"
    class="h-9 px-5 font-inter text-sm rounded-full flex justify-center items-center max-w-min whitespace-nowrap"
    :class="{
      'bg-primaryLight text-orange font-bold': active || removable,
      'bg-sky text-black': !active && !active1,
       'bg-orange text-black font-bold': active1,
    }"
  >
    <slot></slot>
    <vue-feather
      @click="remove"
      class="content-center py-1.5 pl-3"
      size="14"
      :type="'x'"
      v-if="removable"
    ></vue-feather>
    <vue-feather
      @click="DiffUni"
      class="content-center py-1.5 pl-3"
      size="14"
      :type="'chevron-down'"
      v-if="dropdown"
    ></vue-feather>
  </button>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
      active1: {
      type: Boolean,
      default: false,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    dropdown:{
       type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      removed: false,
    };
  },
  emits: ["remove","ChooseDiffUni"],
  methods: {
    remove() {
      this.$emit("remove");
      this.removed = true;
    },
    DiffUni() {
        this.$emit("ChooseDiffUni");
    },
  },
};
</script>
