<template>
  <modal :active="(active || persist) && !closed" :key="key">
    <div class="flex flex-col w-80">
      <Avatar :user="user" class="content-center" :name="true" :username="false" size="small" />
      <p class=' text-orange mx-9 -my-5'>@{{ user.username }}</p>

      <div class="relative w-full h-36 mb-3">
        <textarea
          class="text-base relative font-normal p-2.5 pr-7 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-400 rounded my-7 w-full h-32 focus:border-orange focus:outline-none"
          placeholder="Ask Anything..." v-model="text" @keyup="setText"></textarea>

        <!-- Attachments Icon -->
        <input type="file" @change="setAttachment" class="absolute right-2 top-10 w-16 opacity-0 z-10">
        <div class="absolute right-2 top-10">
          <div class="ml-3">
            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.01453 14.589C6.1766 16.4703 3.20414 16.4703 1.36606 14.589C-0.455353 12.7155 -0.455353 9.68015 1.36606 7.80651L7.97563 1.05607C9.35663 -0.352022 11.5848 -0.352022 12.9657 1.05607C13.6275 1.72781 14 2.64374 14 3.59945C14 4.55528 13.6275 5.47122 12.9657 6.14296L6.901 12.3334C6.46216 12.7901 5.86344 13.0472 5.23888 13.0472C4.61423 13.0472 4.01548 12.7901 3.57664 12.3334C3.13697 11.8831 2.88975 11.2717 2.88975 10.6338C2.88975 9.99599 3.13696 9.3844 3.57664 8.93412L7.4226 5.01509C7.49568 4.93932 7.59516 4.89679 7.69899 4.89679C7.8027 4.89679 7.90219 4.93932 7.97539 5.01509L8.39576 5.43896C8.46945 5.51399 8.51081 5.61629 8.51081 5.72294C8.51081 5.82946 8.46945 5.93177 8.39576 6.00679L4.5498 9.92582C4.22445 10.3271 4.25146 10.9172 4.61191 11.2854C4.97236 11.6537 5.54685 11.678 5.93552 11.3415L12.0002 5.15897C12.4038 4.74739 12.6309 4.18749 12.6309 3.6033C12.6309 3.01911 12.4038 2.45908 12.0002 2.04763C11.1561 1.18541 9.79253 1.18541 8.94841 2.04763L2.32341 8.79807C1.03669 10.1246 1.03669 12.2704 2.32341 13.5971C3.63134 14.915 5.72544 14.915 7.03346 13.5971L11.432 9.11013C11.587 8.95152 11.8376 8.95152 11.9925 9.11013L12.4052 9.53409C12.4804 9.60664 12.5231 9.70795 12.5231 9.81398C12.5231 9.92 12.4804 10.0213 12.4052 10.094L8.01453 14.589Z"
                fill="#FF8303" />
            </svg>
          </div>
        </div>
      </div>
      <div :key="updateAttachment">

        <div class="py-2 flex flex-row space-x-1 relative w-full items-center" v-if="attachment">
          <vue-feather type="file" size="20" stroke="orange"></vue-feather>
          <p class="text-sm w-60 truncate">{{ attachment }}</p>
          <vue-feather class="absolute right-0" size="16" type="trash-2" @click="removeAttachment"></vue-feather>

        </div>

      </div>

      <vue-feather v-if="editable" @click="DeleteIt()" stroke="red"
        class="absolute top-5 right-14 lg:left-80  lg:top-20" size="24" type="trash-2"></vue-feather>

      <div class="flex flex-row items-center  justify-center space-x-48 pt-2">
        <p class="font-inter text-sm font-medium leading-4 w-full px-2">Tags</p>
      </div>

      <!-- <Button class="mr-1" color="primary" shape="ghost" size="small" @click="addTags()">
          Add Tags
        </Button> -->

      <div class="flex flex-row flex-wrap py-1">
        <filter-tag v-if="user.university?.abbrev" class="m-1 uppercase" :active="true">{{ user.university.abbrev }}
        </filter-tag>
        <div class="max-w-fit max-h-fit relative">
          <filter-tag :dropdown="true" :active="true"
            class="uppercase m-1" >{{
              category_name
            }}</filter-tag>
          <!-- <filter-tag v-for="(tag, i) in tags" :key="i" :active="true" :removable="tag != university"
          @remove="removeTag(tag)" class="m-1" :class="{ 'uppercase': tag == university }">{{ this.$store.state.tags[tag.tag_id - 1]?.name ?? tag.tag_id
          }}</filter-tag> -->
          <select class="inset-0 absolute opacity-0" @change="setCategory($event.target.value)">
            <option :key="i" v-for="(_category, i) in this.$store.state.categories" :value="_category.id">{{
                _category.name
            }}</option>
          </select>
      </div>
    </div>
    <div class="flex flex-row items-center justify-between mt-3 mb-3">
      <p class="font-inter text-sm whitespace-nowrap font-medium leading-4 ml-2">
        Post anonymously
      </p>
      <toggle class="mr-1" :active="anonymous" @switch="setAnonymous($event)" />
    </div>

    <Button v-if="!posting" class="h-12" color="primary" shape="full" size="large" @click="postIt()">
      {{ canEdit ? 'Update' : 'Post' }}
    </Button>
    <Button v-else class="h-12 animate-pulse" color="primary" shape="full" size="large">
      {{ canEdit ? 'Updating...' : 'Posting...' }}
    </Button>
    </div>
  </modal>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import toggle from "./toggle.vue"
import Avatar from "./Feed/Avatar.vue";
import modal from './modal.vue';
import Button from "./Feed/Button.vue";
import FilterTag from "./Feed/FilterTag.vue";
import { createPost, DeletePost, EditPost } from '../helpers/API/Feeds';
import { uploadAttachment } from '../helpers/api'
export default {

  components: { modal, Avatar, Button, toggle, FilterTag },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    initial: {
      type: Object,
      default: {}
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      user: null,
      firstname: "",
      lastname: "",
      university: "",
      key: 0,
      text: this.initial.posts_text,
      anonymous: !!this.initial.is_anonymous,
      tags: this.initial.tags || [],
      closed: false,
      posting: false,
      category_name: "",
      persist: this.initial.active,
      canEdit: this.editable,
      category: this.initial.category ?? 1,
      attachment: this.initial.post_attachment,
      updateAttachment: 0,
      file_event: null,
      open: false,
    };
  },
  created() {
    this.showModal = false;
    this.setUser();
    this.setCategory(this.initial.category_id ?? this.$store.state.categories[0].id)
    if (this.initial.tags?.length > 0 && this.$store.state.postPopup.tags.length === 0) this.$store.commit('postPopup/set', { tags: this.initial.tags });
  },
  emits: ["close", "edit"],
  methods: {
    ...mapMutations(["fireToast"]),
    ...mapActions(["completeQuest"]),
    setUser() {
      this.user = this.$store.state.user;
      // splitting name into firstname and lastname
      const name = this.user.name.split(" ");
      this.firstname = name[0];
      name.shift();
      this.lastname = name.join(" ");
      this.lastname = this.lastname.replace(/\s+/g, " ").trim();
      this.university = this.user.university.abbrev;
    },
    setCategory(id) {
      this.category = id;
      this.category_name = this.$store.state.categories.find(cat => cat.id == this.category).name
    },
    setAttachment(e) {
      this.attachment = e.target.files[0].name;
      this.file_event = e;
      this.updateAttachment++;
    },
    removeAttachment() {
      this.attachment = null;
      this.file_event = null;
      this.updateAttachment++;
    },
    addTags() {
      this.$store.commit('postPopup/set', {
        text: this.text,
        tags: this.tags,
        is_anonymous: this.anonymous,
        active: false,
      })
      this.closePopUp()
      this.$router.push('/search-tags')
    },
    setAnonymous(isAnonymous) {
      this.anonymous = isAnonymous
      this.$store.commit('postPopup/set', { isAnonymous })
    },
    async update() {
      let post_attachment = this.attachment ?? "";
      if (post_attachment !== this.initial.post_attachment && this.file_event) post_attachment = await uploadAttachment(this.file_event)

      const postObject = {
        post_id: this.initial.post_id,
        text: this.text,
        is_anonymous: this.anonymous ? 1 : 0,
        category_id: this.category,
        post_attachment,
        tags: [
          { name: this.university },
          { name: this.category }
        ],
      }
      await EditPost(postObject)
      this.$emit("edit", postObject)
      // location.reload()
      this.closePopUp()
      this.fireToast({
        text: `Updated!`,
        type: "confirmation",
      });
    },
    async postIt() {
      this.posting = true;
      if (this.canEdit) return this.update();

      const text = this.text.replace(/\s/g, '');
      if (text.length === 0) return this.fireToast({
        text: `Please write a message`,
        type: "error",
      });

      let post_attachment;

      if (this.attachment) post_attachment = await uploadAttachment(this.file_event)

      await createPost({
        text: this.text,
        is_anonymous: this.anonymous ? 1 : 0,
        category_id: this.category,
        post_attachment,
        tags: [
          { name: this.university }
        ],
      })
       this.completeQuest("introductory posts") 
      this.completeQuest("Create 5 posts") 
      if(this.category==6){
        this.completeQuest("Post 1 full class notes")
        this.completeQuest("Post 2 class notes teasers")
      }
      
      this.fireToast({
        text: `Posted!`,
        type: "confirmation",
      });
      this.$store.commit('postPopup/deactivate');
      this.closePopUp()
      
    },
    closePopUp() {
      this.key++;
      this.closed = true;
      this.$emit('close');
    },
    removeTag(tag) {
      this.$store.commit('postPopup/removeTag', tag);
    },
    setText(e) {
      this.text = e.target.value;
      this.$store.commit('postPopup/set', { text: this.text });
    },
    async DeleteIt() {
      if (confirm('Are you sure you want to delete this post?')) {
        this.closePopUp();
        await DeletePost(this.initial.post_id)

        this.fireToast({
          text: `Post Deleted!`,
          type: "confirmation",
        });

        this.$router
          .replace('/')
          .then(() => { this.$router.go() })
      }
    }

  },
  watch: {
    '$store.state.postPopup.tags': {
      deep: true,
      immediate: true,
      handler(val) {
        this.tags = val;
      }
    },
    university: function (val) {
      if (val && !this.tags.includes(val))
        this.tags = [val, ...this.tags]
    }
  }
};

</script>

