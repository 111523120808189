import tutoruuAPI from "../../tutoruuAPI";
import store from "../store";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

export const getTutors = async (options = {}) => {
  let q = "?";
  if (!!options.limit) q += `limit=${options.limit}&`;
  if (!!options.page) q += `page=${options.page}&`;
  if (!!options.university) q += `university_abbrev=${options.university}&`;
  if (!!options.department) q += `department=${options.department}&`;
  if (!!options.sort) q += `sorting_method=${options.sort}&`;

  q.slice(0, -1);
  return await tutoruuAPI.get(`tutor${q}`);
};

export const getUniversityDepartments = async (id) => {
  return await tutoruuAPI.get(`university/${id}/departments`);
};

export const getTutor = async (username) => {
  return await tutoruuAPI.get(`tutor/username/${username}`);
};

/**
 * @description Updates the current user in the database
 * @param {*} userData
 * @returns Updated User
 */
export const updateCurrentUser = async (userData) => {
  // grab the id from the user
  const { _id } = store.state.user;

  try {
    // update user in database
    const user = await tutoruuAPI.patch(`user/${_id}/fields`, {
      fields: userData,
    });

    // update state user
    store.commit("updateUser", userData);

    // fire notification
    store.commit("fireToast", {
      text: `Profile updated`,
      type: "confirmation",
    });

    // return the new user
    return user;
  } catch (e) {
    console.error(e);
    store.commit("fireToast", {
      text: `Something went wrong. Try again in a few minutes`,
      type: "error",
    });
  }
};

export const removeTutor = async (id) => {
  return await tutoruuAPI.delete(`tutor/${id}`);
};

export const uploadProfilePicture = async (event) => {
  const file = event.target.files[0];
  const myFile = file;
  try {
    if (file && file.name) {
      store.state.imageLoading = true;
      const fr = new FileReader();
      fr.readAsDataURL(file);

      fr.addEventListener("load", () => {
        // this is to load image on the UI
        // .. not related to file upload :)
        return fr.result;
      });
      const imgData = new FormData();
      imgData.append("image", myFile);
      const filePath = `images/users/${
        store.state.user.username
      }/profile_picture/${Date.now()}-${file.name}`;
      const metadata = { contentType: myFile.type };
      const storage = getStorage();
      const profileImageRef = ref(storage, filePath);
      const uploadTask = uploadBytesResumable(
        profileImageRef,
        myFile,
        metadata
      );
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;
            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            store.state.user.img_url = downloadURL;
            store.state.imageLoading = false;
          });
        }
      );
    }
  } catch (e) {
    console.error(e);
  }
};

export const uploadAttachment = async (event) => {
  const file = event.target.files[0];
  const myFile = file;
  try {
    if (file && file.name) {
      const fileData = new FormData();
      fileData.append("file", myFile);
      const filePath = `attachments/${
        store.state.user.username
      }/post/${Date.now()}___${file.name}`;

      const metadata = { contentType: myFile.type };
      const storage = getStorage();
      const profileImageRef = ref(storage, filePath);
      return new Promise((resolve, reject) => {
        const uploadTask = uploadBytesResumable(
          profileImageRef,
          myFile,
          metadata
        );
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;
              case "storage/canceled":
                // User canceled the upload
                break;
              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        );
      });
    }
  } catch (e) {
    console.error(e);
  }
};
