<template>
  <button
    :type="type"
    class="relative flex items-center transition duration-300 ease-out text-center align-middle rounded-full"
    :class="{
      'w-full': shape === 'full',
      'max-w-min': shape !== 'full',
      'hover:shadow-lg active:shadow ': shape !== 'ghost' && !disabled,
      'px-8 py-4': shape !== 'ghost' && size === 'normal',
      'px-3 py-2': shape !== 'ghost' && size === 'small' && !icon,
      'bg-primary text-white': shape !== 'ghost' && color === 'primary' && !disabled,
      'bg-primaryLight text-primary': shape !== 'ghost' && color === 'secondary' && !disabled,
      'bg-danger text-white': shape !== 'ghost' && color === 'danger' && !disabled,
      'text-primary': shape === 'ghost' && color === 'primary',
      'text-white': shape === 'ghost' && color === 'secondary',
      'text-danger': shape === 'ghost' && color === 'danger',
      'bg-gray-200 text-gray-400': disabled,
      'pr-10': icon && iconPosition === 'right' && size === 'normal' && shape !== 'full',
      'pl-10': icon && iconPosition === 'left' && size === 'normal' && shape !== 'full',
      'pr-6': icon && iconPosition === 'right' && size === 'small',
      'pl-6': icon && iconPosition === 'left' && size === 'small',
    }"
    @click.stop="clicked"
  >
    <p class="font-bold font-poppins whitespace-nowrap text-center mx-auto">
      <slot></slot>
    </p>
    <vue-feather
      v-if="icon"
      :type="icon"
      class="w-6 h-6 absolute top-0 bottom-0 my-auto"
      :class="{ 'left-2': iconPosition === 'left', 'right-2': iconPosition === 'right' }"
    ></vue-feather>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    type: {
      type: String,
      enum: ["button", "submit"],
      default: "button",
    },
    color: {
      type: String,
      enum: ["primary", "secondary", "danger"],
      default: "primary",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    shape: {
      type: String,
      enum: ["ghost", "full", "default"],
      default: "default",
    },
    size: {
      type: String,
      enum: ["small", "normal"],
      default: "normal",
    },
    to: {
      type: String,
      default: null,
    },
    routing: {
      type: String,
      enum: ["replace", "push"],
      default: "push",
    },
    icon: {
      type: String,
      default: null,
    },
    iconPosition: {
      type: String,
      enum: ["left", "right"],
      default: "right",
    },
  },
  emits: ["click"],
  methods: {
    clicked() {
      if (!this.disabled) {
        this.$emit("click");

        if (this.to) this.$router[this.routing](this.to);
      }
    },
  },
};
</script>
