<template>
  <div class="flex relative m-1" @click="redirect">
    <div class="relative rounded-full" :class="{
      'w-8 h-8': size == 'small',
      'w-12 h-12': size == 'medium',
      'w-20 h-20': size == 'large',
      'w-10 h-10': size == 'SmallMedium',
      'p-0.5 ring-1 ring-offset-white ring-primary': tutor || !!user.tutor || !!user.is_tutor
    }">
      <!-- 'p-1 ring-2 ring-offset-white ring-primary': tutor || !!user.tutor, -->
      <div v-if="user.is_tutor==1 || tutor1 && showCap" class="absolute -mx-3 -my-2 z-30">
    <svg width="30" height="30" viewBox="0 0 33 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.8573 24.1361C9.66732 21.9623 8.57338 20.5328 8.10337 18.9185C8.0844 18.8561 8.06607 18.791 8.04657 18.7182C8.21492 18.5571 8.32442 18.3442 8.35766 18.1136C8.3909 17.8829 8.34594 17.6477 8.22993 17.4456C8.1331 17.2929 7.99384 17.1717 7.82923 17.0969C7.88138 16.286 8.29228 15.6378 9.59789 15.8607C10.6141 16.0357 12.6352 19.1959 12.8936 20.5226C23.5922 9.79114 30.4912 8.65966 30.4912 8.65966C28.9837 8.2929 27.8122 6.62416 26.4741 5.55483C27.2579 4.53067 28.1082 3.5592 29.0196 2.64664C30.1082 1.75128 31.2434 0.914187 32.4205 0.138962C17.9445 -0.339522 10.2289 10.293 0.663765 17.8077C0.783863 18.064 0.899167 18.3221 1.02139 18.5818C2.96253 17.9731 4.89592 17.3658 6.82144 16.7599C6.92469 16.8439 7.02291 16.9275 7.12287 17.0101C6.98108 17.0414 6.84555 17.0963 6.72187 17.1723C6.47138 17.3128 6.28669 17.5468 6.20812 17.823C6.12955 18.0993 6.16349 18.3953 6.30255 18.6466C6.34629 18.7147 6.39915 18.7766 6.45971 18.8303C6.20699 19.1887 6.07194 19.6168 6.07323 20.0553C6.55574 24.6877 7.25094 29.2981 7.98181 33.9015C8.02192 34.185 8.10647 34.4605 8.23245 34.7176C8.27345 34.8037 8.32414 34.8848 8.38352 34.9594L9.06882 35.3294L9.67142 34.7841L10.0828 35.5707C10.3957 35.4993 10.6963 35.3818 10.9746 35.222L11.8664 34.3451C12.8905 30.9423 12.5278 27.2725 10.8573 24.1361Z" fill="#FF8303"/>
</svg>
</div>
      <img class="rounded-full border-gray-100 shadow-sm w-full h-full" :src="img" :alt="`profile image`" />
      <div v-if="this.$store.state.imageLoading" class="
          absolute
          inset-0
          rounded-full
          bg-white bg-opacity-90
          flex
          justify-center
          items-center
        ">
        <ion-spinner name="lines" color="primary" class="w-8 h-8" />
      </div>
    </div>
    <div class="flex flex-col pl-2" v-if="name || username">
      <div class="flex items-center space-x-1">
        <p class="text-body font-bold truncate capitalize" style="max-width: 10rem !important" v-if="name">
          {{ anonymous ? "Anonymous" : user.name ?? user.first_name ?? user.full_name }}
        </p>
        <p class="text-body text-gray-300 " :class="{ 'my-2': myProfile }" v-if="name && username && !anonymous">
          •
        </p>
        <p class="text-body-small text-primary truncate" style="max-width: 8rem !important"
          v-if="username && !anonymous">
          @{{ user.username }}
        </p>
      </div>
      <p class="
          text-grey text-sm
          leading-4
          font-normal font-inter
          text-justify
          mx-0
          my-1
          uppercase
        " v-if="university">
        {{ user.university && user.university.abbrev ? user.university.abbrev : user.university }}
      </p>
    </div>
  </div>
</template>

<script>
import { getUser } from '../../helpers/API/Core/User'
import { IonSpinner } from "@ionic/vue";
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      enum: ["small", "medium", "large"],
      default: "medium",
    },
    university: {
      type: Boolean,
      default: false,
    },
    tutor1: {
      type: Boolean,
      default: false,
    },
    name: {
      type: Boolean,
      default: false,
    },
    username: {
      type: Boolean,
      default: false,
    },
    anonymous: {
      type: Boolean,
      default: false,
    },
    myProfile: {
      type: Boolean,
      default: false,
    },
    isCurrentUser: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      default: null,
    },
    showCap: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    IonSpinner,
  },
  data() {
    return {
      tutor: false,
      pic: [],
      img: null,
    };
  },
  created() {
    if (this.anonymous) return this.img = this.$store.state.filler_img
    if (!!this.user.user_profiles && this.user.user_profiles[0]) return this.img = this.user.user_profiles[0].profile_picture || this.$store.state.filler_img
    this.img = this.image || this.user.img_url || this.user.profile_picture || this.$store.state.filler_img
  },
  methods: {
    redirect() {
      if (this.anonymous) return;
      if (this.isCurrentUser) return this.$router.push("/profile");
      const is_tutor = this.tutor1 || this.showCap || !!this.user.is_tutor;
      if (is_tutor)
        return this.$router.push(`/tutor/${this.user.username}`);
      if (!is_tutor && !this.isCurrentUser) return this.$router.push(`/u/${this.user.username}`);
    },
  },
  watch: {
    "$store.state.user.img_url": async function () {
      if (this.isCurrentUser) this.img = this.$store.state.user.img_url
    },
  },
};
</script>


