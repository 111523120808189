export function clickOutside(node, cb) {
  if (!node) return;
  const handleClick = (event) => {
    if (!node.contains(event.target)) {
      cb();
    }
  };

  document.addEventListener("click", handleClick, true);

  return {
    destroy() {
      document.removeEventListener("click", handleClick, true);
    },
  };
}
