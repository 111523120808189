<template>
  <page-layout title="Profile" linkDefault="/" :backButton="true" :key="changeUser">
    <Loading v-if="loading" />
    <div v-else>
      <div class="w-full flex justify-end fixed bottom-24 px-4 z-50">
        <!-- <Button color="primary" @click="this.$router.push(`/tutor/${user.username}`)" icon="chevron-right">View Tutor
        Profile</Button> -->
      </div>
      <!-- <Loading v-if="loading" /> -->
      <div class="flex flex-row items-start justify-start m-5 space-y-0 w-full">
        <div v-if="tutor" class="relative">
          <div class="absolute mx-12 my-16 z-50">
            <vue-feather @click="RedirectToTutorProfile()" class="absolute mx-3" stroke-width="4" stroke="orange"
              type="check-circle"></vue-feather>
          </div>
          <div class="absolute -my-0 -mx-1 z-30">
            <svg class="absolute " width="37" height="40" viewBox="0 0 33 36" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.8573 24.1361C9.66732 21.9623 8.57338 20.5328 8.10337 18.9185C8.0844 18.8561 8.06607 18.791 8.04657 18.7182C8.21492 18.5571 8.32442 18.3442 8.35766 18.1136C8.3909 17.8829 8.34594 17.6477 8.22993 17.4456C8.1331 17.2929 7.99384 17.1717 7.82923 17.0969C7.88138 16.286 8.29228 15.6378 9.59789 15.8607C10.6141 16.0357 12.6352 19.1959 12.8936 20.5226C23.5922 9.79114 30.4912 8.65966 30.4912 8.65966C28.9837 8.2929 27.8122 6.62416 26.4741 5.55483C27.2579 4.53067 28.1082 3.5592 29.0196 2.64664C30.1082 1.75128 31.2434 0.914187 32.4205 0.138962C17.9445 -0.339522 10.2289 10.293 0.663765 17.8077C0.783863 18.064 0.899167 18.3221 1.02139 18.5818C2.96253 17.9731 4.89592 17.3658 6.82144 16.7599C6.92469 16.8439 7.02291 16.9275 7.12287 17.0101C6.98108 17.0414 6.84555 17.0963 6.72187 17.1723C6.47138 17.3128 6.28669 17.5468 6.20812 17.823C6.12955 18.0993 6.16349 18.3953 6.30255 18.6466C6.34629 18.7147 6.39915 18.7766 6.45971 18.8303C6.20699 19.1887 6.07194 19.6168 6.07323 20.0553C6.55574 24.6877 7.25094 29.2981 7.98181 33.9015C8.02192 34.185 8.10647 34.4605 8.23245 34.7176C8.27345 34.8037 8.32414 34.8848 8.38352 34.9594L9.06882 35.3294L9.67142 34.7841L10.0828 35.5707C10.3957 35.4993 10.6963 35.3818 10.9746 35.222L11.8664 34.3451C12.8905 30.9423 12.5278 27.2725 10.8573 24.1361Z"
                fill="#FF8303" />
            </svg>
          </div>
          <Avatar :user="user" size="large" class="gap-3 " />
        </div>
        <Avatar v-else :user="user" :image="picture" size="large" class="gap-3 " />
        <div class="flex flex-col space-y-1.5">
          <div class="flex flex-row items-center justify-between">
            <tag class="h-7 uppercase font-poppins" :text="user.university?.abbrev" :active="true"
              v-if="user.university?.abbrev">
            </tag>
            <div class="w-64 ml-24">
              <Button v-if="!isCurrentUser" :key="update" color="primary" :shape="following ? 'default' : 'ghost'"
                class="ring-1 ring-primary font-poppins text-xs  w-45 h-3 mt-1 p-3 pl-5 pr-5 " @click="toggleFollow">
                {{ following? 'following': 'follow ' }}</Button>
            </div>
          </div>
          <p class="w-44 heading-1 capitalize ml-1 truncate">{{ user.name }}</p>
          <div class="flex flex-row space-x-2 items-center ml-1">
            <p class="font-poppins text-sm font-bold text-gray-300 mt-1">{{ followers }} followers</p>
          </div>
        </div>
      </div>
      <div v-if="user.bio!=null" class='flex flex-row items-center justify-start mr-5 ml-8'>
        <p class="font-poppins font-semibold text-sm leading-6 align-middle">Bio</p>
      </div>
      <div v-if="user.bio!=null" class='flex flex-row items-center justify-start mr-6 ml-8'>
        <p>{{ user.bio }}</p>
      </div>
      <br>
      <hr>
      <div class='flex flex-row items-center justify-start mr-5 ml-8 mt-5 mb-5'>
        <p class="font-poppins font-semibold text-sm leading-6 align-middle">Classes</p>
      </div>
      <div v-if="user.classes.length > 0"
        class="flex flex-row pb-3 gap-4 ml-2 pl-5 pr-5 scrollbar-hide whitespace-normal scroll scroll-smooth overflow-x-auto">
        <class-card v-for="(_class, i) in user.classes" :key="i" :_class="_class"
          :university="user.university.abbrev" />
      </div>
      <div class="ml-10 mr-10 mb-5" v-else >
        <EmptyState iconType="info" text="No Classes yet" />
      </div>
      <div class='flex flex-row items-center justify-start mb-5 mr-5 ml-8' >
        <p class="font-poppins font-semibold text-sm leading-6 align-middle">Recent Posts</p>
      </div>
      <div v-if="posts.length > 0" class="flex flex-col justify-center items-center ml-5 mr-7 mt-2 gap-y-5">
        <post  v-for="(_post, i) in posts.filter(p => !p.is_anonymous)" :key="i" :post="{ ..._post, user: user2 }" />
      </div>
      <div  class="ml-10 mr-10 mb-5"  v-else>
        <EmptyState iconType="info" text="No Posts Yet" />
      </div>
    </div>
  </page-layout>
</template>

<script>
import Loading from "../../components/Loading.vue";
import Button from "../../components/Feed/Button.vue";
import Avatar from "../../components/Feed/Avatar.vue";
import FilterTag from "../../components/Feed/FilterTag.vue";
import tag from '../../components/tag.vue'
import ClassCard from "../../components/Feed/ClassCard.vue";
import Post from "../../components/Feed/Post.vue";
import pageLayout from "../../components/base/pageLayout.vue";
import { getUser } from "../../helpers/API/Core/User";
import { getProfileByUsername } from "../../helpers/API/Feeds/User";
import { follow, unfollow } from '../../helpers/API/Feeds/User';
import EmptyState from "../../components/Feed/EmptyState.vue";
export default {
  components: { Button, Avatar, tag, FilterTag, ClassCard, Post, pageLayout, Loading, EmptyState },

  data() {
    return {
      user: null,
      username: "",
      firstname: "",
      lastname: "",
      university: "",
      following: false,
      followers: 0,
      update: 0,
      isCurrentUser: false,
      user2: null,
      posts: [],
      changeUser: 0,
      loading: true,
      tutor: false,
      picture:'',
    };
  },

  created() {
    this.setUser();
  },

  methods: {
    set(prop, val) {
      this.updated = true;
      this[prop] = val;
    },
    RedirectToTutorProfile() {
      this.$router.push(`/tutor/${this.user.username}`);
    },

    toggleFollow() {
      this.following = !this.following;
      if (this.following) {
        this.followers++;
        follow(this.user2.user_id)
      }
      else {
        this.followers--;
        unfollow(this.user2.user_id)
      }

      this.update++;
    },

    async setUser() {
      getUser(this.$route.params.username, true).then(data => {
        if (data != null) {
          this.user = data;
          this.username = this.$route.params.username;
          if (this.user.tutor != null) {
            this.tutor = true
          }
          // this.followers = this.user.feed_user.followers.length
          // if (this.user.feed_user.followers.includes(this.$store.state.user.feed_user_id)) this.following = true
          // this.getPosts()
          getProfileByUsername(this.$route.params.username).then(({ data }) => {
            this.user2 = data.user_profile
            if (this.user2.is_tutor == 1) {
              this.tutor = true
            }
            this.picture=this.user2.profile_picture;
            this.posts = data.user_posts.data
            this.followers = data.counters.followers_count;
            this.following = data.flags.is_followed_by_user;
            this.loading = false;
          });
        }
      })
    },

    // getting posts of a specific user
    // async getPosts() {
    //   getPostsbyUser(this.user.feed_user_id, { limit: 20, page: 1 }).then(data => {
    //     this.posts = data.posts;
    //     this.posts.reverse();
    //     for (var i = 0; i < this.posts.length; i++) {
    //       if (this.posts[i].isAnonymous === true) {
    //         this.posts.splice(i, 1);
    //       }
    //     }
    //   })
    //   this.loading = false;
    // },
  },
  watch: {
    "$route.params.username": {
      handler: function () {
        if (this.$route.params.username) {
          this.setUser();
          // this.followers = this.user.feed_user.followers.length
        } else this.setUser();

        this.changeUser++;
      },
      deep: true,
      immediate: true,
    },
  },
}

</script>

