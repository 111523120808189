<template>
  <div class="w-full relative max-w-lg rounded-xl bg-white shadow-md">
    <div v-if="isCurrentUser" class="absolute overlay flex flex-row items-end justify-end mx-72 ">
      <div class="px-5 pt-7" id="controls">
        <div class="text-left inline-flex flex-col w-20">
          <button :key="updateOptionPopUp" @click="(optionsPopUp)" class="p-0 text-gray-300 bg-transparent z-10 ">
            •••
          </button>
          <div class="border-orange bg-white w-52 -mx-36 z-30" :class="{ 'hidden': !open }">
            <div class="rounded-3xl shadow-2xl p-2">
              <a @click="DeleteIt()" class="block border-b border-gray-200">
                <div class="flex flex-row p-2 space-x-1 justify-between rounded-full">
                  <div class="font-inter text-xs text-red-600">Delete</div>
                  <vue-feather size="20" stroke="red" type="trash-2"></vue-feather>
                </div>
              </a>
              <a @click="postpopup" class="block border-b border-gray-200">
                <div class="flex flex-row p-2 space-x-11 justify-between">
                  <div class="font-inter text-xs">Edit</div>
                  <vue-feather size="20" type="edit-2"></vue-feather>
                </div>
              </a>
              <a @click="report()" class="block border-gray-200">
                <div class="flex flex-row p-2 space-x-11 justify-between">
                  <div class="font-inter text-xs">Report</div>
                  <vue-feather size="20" type="flag"></vue-feather>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col w-full p-3">
      <div class="flex flex-wrap pl-10 -mb-2" v-if="this.$store.state.categories && post.category_id">
        <Tag :text="this.$store.state.categories.find(cat => cat.id == post.category_id).name.toUpperCase()" small />
        <!-- <div v-for="(tag, i) in post.tags" :key="i">
            <Tag v-if="i < 2" :text="this.$store.state.tags[tag.tag_id ?? tag.id]" small />
          </div>
          <div>
            <Tag
              v-if="post.tags.length > 2"
              :text="`+${post.tags.length - 2}`"
              small
            />
          </div> -->
      </div>
      <Avatar class="content-center" :user="user" :anonymous="!!post.is_anonymous" :university="false" name
        :image="imageUrl" :isCurrentUser="isCurrentUser" :showCap="tutor" :tutor1="tutor" username size="small" />
      <div class="flex mx-11 -mt-0.5">
        <p @click="redirectToPostPage" class="
              text-black
              font-poppins
              leading-5
              font-normal
              -mt-3
              text-xs text-left
              break-all
            "
            >
          {{ post.posts_text }}
        </p>
      </div>
    </div>

    <div class="pb-2" v-if="postImage">
      <img :src="postImage" class="w-full h-auto md:max-w-lg rounded-md mx-auto" style="max-width: 90% !important;">
    </div>

    <div class="pb-2 pl-12 flex space-x- items-center" v-else-if="post.post_attachment">
      <vue-feather type="file" size="16" stroke="orange"></vue-feather>
      <a target="_blank" :href="post.post_attachment" class="text-xs text-primary font-semibold">view attachment</a>
    </div>

    <div class="flex w-full flex-row pt-2 pb-3 space-x-9   justify-center" :class="{ 'opacity-50': !isCurrentUniversity }">
      <div class="flex items-center">
        <!-- <vue-feather @click="redirectForComment" stroke="grey" size="14" type="message-square"></vue-feather> -->
        <svg @click="redirectForComment" width="16" height="15" viewBox="0 0 16 15" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.25 2.625C13.7242 2.625 14.125 3.02575 14.125 3.5V9.625C14.125 10.0992 13.7242 10.5 13.25 10.5H5.52462L5.375 10.6496V10.5H2.75C2.27575 10.5 1.875 10.0992 1.875 9.625V3.5C1.875 3.02575 2.27575 2.625 2.75 2.625H13.25ZM13.25 0.875H2.75C1.30625 0.875 0.125 2.05625 0.125 3.5V9.625C0.125 11.0688 1.30625 12.25 2.75 12.25H3.625V14.875L6.25 12.25H13.25C14.6938 12.25 15.875 11.0688 15.875 9.625V3.5C15.875 2.05625 14.6938 0.875 13.25 0.875Z"
            fill="#CFCDCB" />
        </svg>
        <span class="font-inter text-xs ml-1">{{ comments }}</span>
      </div>
      <div class="flex items-center">
        <!-- <vue-feather @click="react('upvote')" :fill="isLiked ? 'orange' : 'none'" :stroke="isLiked ? 'orange' : 'grey'"
            size="16" type="thumbs-up"></vue-feather> -->
        <svg @click="react('upvote')" v-if="!isLiked" width="15" height="16" viewBox="0 0 15 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.6237 6.0915C13.2816 5.9655 11.4258 5.73625 10.2261 5.6015C10.4143 4.50338 10.5 3.49712 10.5 2.4375C10.5 1.23088 9.51825 0.25 8.3125 0.25C7.10675 0.25 6.125 1.23088 6.125 2.4375C6.125 4.07812 5.54225 4.83325 4.711 5.67413C4.2315 5.04238 3.47988 4.625 2.625 4.625C1.17775 4.625 0 5.80275 0 7.25V12.5C0 13.9473 1.17775 15.125 2.625 15.125C3.28562 15.125 3.88325 14.8712 4.34438 14.467L4.50887 14.6359C5.34888 15.2799 7.7175 16 9.62588 16C11.27 16 11.9079 15.7436 12.4722 15.5161L12.7487 15.4085C13.4785 15.1416 14.1277 14.3349 14.2677 13.4835L14.8505 8.25625C15.0019 7.34887 14.4629 6.39687 13.6237 6.0915ZM2.625 13.375C2.14287 13.375 1.75 12.983 1.75 12.5V7.25C1.75 6.767 2.14287 6.375 2.625 6.375C3.10713 6.375 3.5 6.767 3.5 7.25V12.5C3.5 12.983 3.10713 13.375 2.625 13.375ZM12.5361 13.2438C12.5037 13.4398 12.2806 13.7171 12.1485 13.7652L11.8195 13.893C11.3426 14.0846 10.9305 14.25 9.625 14.25C7.95025 14.25 6.02175 13.5911 5.572 13.2472C5.43375 13.1423 5.25 12.7537 5.25 12.5V8.1425C5.25262 8.10138 5.29462 7.5685 5.86862 6.9945C6.6675 6.19475 7.875 4.98725 7.875 2.4375C7.875 2.19687 8.07187 2 8.3125 2C8.55313 2 8.75 2.19687 8.75 2.4375C8.75 3.66862 8.62225 4.82625 8.33088 6.19388L8.071 7.41538L9.22688 7.25088C9.7545 7.29463 12.6683 7.642 13.0191 7.73212C13.0699 7.75837 13.1346 7.89225 13.1163 8.01475L12.5361 13.2438Z"
            fill="#CFCDCB" />
        </svg>
        <svg @click="react('upvote')" width="15" v-else height="16" viewBox="0 0 15 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.6237 6.0915C13.2816 5.9655 11.4258 5.73625 10.2261 5.6015C10.4143 4.50338 10.5 3.49712 10.5 2.4375C10.5 1.23088 9.51825 0.25 8.3125 0.25C7.10675 0.25 6.125 1.23088 6.125 2.4375C6.125 4.07812 5.54225 4.83325 4.711 5.67413C4.2315 5.04238 3.47988 4.625 2.625 4.625C1.17775 4.625 0 5.80275 0 7.25V12.5C0 13.9473 1.17775 15.125 2.625 15.125C3.28562 15.125 3.88325 14.8712 4.34438 14.467L4.50887 14.6359C5.34888 15.2799 7.7175 16 9.62588 16C11.27 16 11.9079 15.7436 12.4722 15.5161L12.7487 15.4085C13.4785 15.1416 14.1277 14.3349 14.2677 13.4835L14.8505 8.25625C15.0019 7.34887 14.4629 6.39687 13.6237 6.0915ZM2.625 13.375C2.14287 13.375 1.75 12.983 1.75 12.5V7.25C1.75 6.767 2.14287 6.375 2.625 6.375C3.10713 6.375 3.5 6.767 3.5 7.25V12.5C3.5 12.983 3.10713 13.375 2.625 13.375ZM12.5361 13.2438C12.5037 13.4398 12.2806 13.7171 12.1485 13.7652L11.8195 13.893C11.3426 14.0846 10.9305 14.25 9.625 14.25C7.95025 14.25 6.02175 13.5911 5.572 13.2472C5.43375 13.1423 5.25 12.7537 5.25 12.5V8.1425C5.25262 8.10138 5.29462 7.5685 5.86862 6.9945C6.6675 6.19475 7.875 4.98725 7.875 2.4375C7.875 2.19687 8.07187 2 8.3125 2C8.55313 2 8.75 2.19687 8.75 2.4375C8.75 3.66862 8.62225 4.82625 8.33088 6.19388L8.071 7.41538L9.22688 7.25088C9.7545 7.29463 12.6683 7.642 13.0191 7.73212C13.0699 7.75837 13.1346 7.89225 13.1163 8.01475L12.5361 13.2438Z"
            fill="#FE8303" />
        </svg>
        <span class="font-inter text-xs ml-1">{{ likes }}</span>
      </div>
      <div class="flex items-center">
        <!-- <vue-feather @click="react('downvote')" :fill="isDisliked ? 'orange' : 'none'"
            :stroke="isDisliked ? 'orange' : 'grey'" size="14" type="thumbs-down"></vue-feather> -->

        <svg v-if="!isDisliked" @click="react('downvote')" width="15" height="16" viewBox="0 0 15 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.375 0.875C11.7143 0.875 11.1167 1.12787 10.6556 1.53212L10.4911 1.36413C9.65109 0.71925 7.28422 0 5.37497 0C3.73084 0 3.09384 0.256375 2.52947 0.483L2.25297 0.5915C1.52322 0.858375 0.872218 1.66687 0.732218 2.5165L0.151218 7.742C-0.00103167 8.65112 0.537093 9.60313 1.37622 9.91025C1.72097 10.0363 3.57422 10.2646 4.77384 10.4002C4.58572 11.4992 4.50084 12.5046 4.50084 13.5643C4.50084 14.7709 5.48172 15.7518 6.68834 15.7518C7.89497 15.7518 8.87584 14.7709 8.87584 13.5643C8.87584 11.9236 9.45947 11.1694 10.2898 10.3276C10.7693 10.9611 11.5218 11.3768 12.3758 11.3768C13.8222 11.3768 15 10.1981 15 8.75175V3.50175C14.9991 2.05275 13.8222 0.875 12.375 0.875ZM7.12497 13.5625C7.12497 13.8031 6.92809 14 6.68747 14C6.44684 14 6.24997 13.8031 6.24997 13.5625C6.24997 11.9831 6.47397 10.7266 6.66909 9.80612L6.92897 8.58288L5.77309 8.74737C5.24372 8.70362 2.33084 8.35625 1.97997 8.26437C1.92922 8.23987 1.86447 8.10687 1.88547 7.9835L2.46559 2.7545C2.49797 2.55937 2.72022 2.28287 2.85322 2.23475L3.18309 2.107C3.65909 1.91537 4.07122 1.75 5.37584 1.75C7.05059 1.75 8.97909 2.40887 9.42972 2.75275C9.56622 2.85775 9.74997 3.24625 9.74997 3.5V7.85488C9.74909 7.87762 9.71497 8.42275 9.13134 9.00638C8.33247 9.80525 7.12497 11.0127 7.12497 13.5625ZM13.25 8.75C13.25 9.233 12.858 9.625 12.375 9.625C11.892 9.625 11.5 9.233 11.5 8.75V3.5C11.5 3.017 11.892 2.625 12.375 2.625C12.858 2.625 13.25 3.017 13.25 3.5V8.75Z"
            fill="#CFCDCB" />
        </svg>
        <svg v-else @click="react('downvote')" width="15" height="16" viewBox="0 0 15 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.375 0.875C11.7143 0.875 11.1167 1.12787 10.6556 1.53212L10.4911 1.36413C9.65109 0.71925 7.28422 0 5.37497 0C3.73084 0 3.09384 0.256375 2.52947 0.483L2.25297 0.5915C1.52322 0.858375 0.872218 1.66687 0.732218 2.5165L0.151218 7.742C-0.00103167 8.65112 0.537093 9.60313 1.37622 9.91025C1.72097 10.0363 3.57422 10.2646 4.77384 10.4002C4.58572 11.4992 4.50084 12.5046 4.50084 13.5643C4.50084 14.7709 5.48172 15.7518 6.68834 15.7518C7.89497 15.7518 8.87584 14.7709 8.87584 13.5643C8.87584 11.9236 9.45947 11.1694 10.2898 10.3276C10.7693 10.9611 11.5218 11.3768 12.3758 11.3768C13.8222 11.3768 15 10.1981 15 8.75175V3.50175C14.9991 2.05275 13.8222 0.875 12.375 0.875ZM7.12497 13.5625C7.12497 13.8031 6.92809 14 6.68747 14C6.44684 14 6.24997 13.8031 6.24997 13.5625C6.24997 11.9831 6.47397 10.7266 6.66909 9.80612L6.92897 8.58288L5.77309 8.74737C5.24372 8.70362 2.33084 8.35625 1.97997 8.26437C1.92922 8.23987 1.86447 8.10687 1.88547 7.9835L2.46559 2.7545C2.49797 2.55937 2.72022 2.28287 2.85322 2.23475L3.18309 2.107C3.65909 1.91537 4.07122 1.75 5.37584 1.75C7.05059 1.75 8.97909 2.40887 9.42972 2.75275C9.56622 2.85775 9.74997 3.24625 9.74997 3.5V7.85488C9.74909 7.87762 9.71497 8.42275 9.13134 9.00638C8.33247 9.80525 7.12497 11.0127 7.12497 13.5625ZM13.25 8.75C13.25 9.233 12.858 9.625 12.375 9.625C11.892 9.625 11.5 9.233 11.5 8.75V3.5C11.5 3.017 11.892 2.625 12.375 2.625C12.858 2.625 13.25 3.017 13.25 3.5V8.75Z"
            fill="#FE8303" />
        </svg>

        <span class="font-inter text-xs ml-1">{{ dislikes }}</span>
      </div>
      <div class="flex items-center">
        <!-- <vue-feather @click="share()" size="14" stroke="grey" type="share"></vue-feather> -->
        <svg @click="share()" width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.664 15.5471C15.023 13.0471 12.995 12.2621 10.5 12.0631V13.5001C10.5 14.0341 10.292 14.5361 9.914 14.9141C9.158 15.6701 7.837 15.6651 7.091 14.9191L0.798 8.71208C0.607 8.52308 0.5 8.26808 0.5 7.99908C0.5 7.73008 0.607 7.47508 0.798 7.28708L7.086 1.08408C7.84 0.329078 9.159 0.328078 9.915 1.08508C10.292 1.46308 10.5 1.96508 10.5 2.49908V4.20308C15.119 5.13608 18.5 9.20008 18.5 13.9991V14.9991C18.5 15.4411 18.21 15.8311 17.786 15.9571C17.691 15.9841 17.596 15.9991 17.5 15.9991C17.169 15.9991 16.854 15.8341 16.664 15.5471ZM9.523 10.0111C11.73 10.0671 14.161 10.4051 16.281 12.1321C15.513 8.91608 12.804 6.43008 9.388 6.05208C8.884 5.99608 8.5 6.00008 8.5 6.00008V2.50308L2.924 7.99908L8.5 13.4991V10.0001L9.523 10.0111Z"
            fill="#CFCDCB" />
        </svg>

      </div>
      <!-- <div class="flex items-center">
          <vue-feather @click="save()" :fill="isSaved ? 'orange' : 'none'" :stroke="isSaved ? 'orange' : 'grey'" size="14"
            type="bookmark"></vue-feather>
        </div> -->
      <!-- <div v-if="viewIcon" class="flex items-end">
          <span @click="redirect" class="text-primary text-xs -ml-2">View</span>
          <vue-feather
            @click="redirect"
            stroke="orange"
            size="16"
            type="arrow-right"
          >
          </vue-feather>
        </div> -->
    </div>
    <SharePost :shareModal="openShareModal" :key="updateShareModal" :post="post" />
    <comment-pop-up :postId="post.post_id" :user="post.user" :key="update" v-if="(showModal && isCurrentUniversity)" />
    <post-pop-up :editable="isCurrentUser" :active="showPostModal" :initial="post"
      :key="updatePost + this.$store.state.postPopup.update" @edit="setPost($event)" />
    <!-- <div class="w-full h-2 bg-gray-100"></div> -->
</div>
</template>

<script>
import { DeletePost, ReportPost } from "../../helpers/API/Feeds";
import { likeOrDislikePost, deleteLikeOrDislikePost, savePost, removeSavedPost } from "../../helpers/API/Feeds/Reaction";
import Avatar from "./Avatar.vue";
import CommentPopUp from "../CommentPopUp.vue";
import PostPopUp from "../PostPopUp.vue";
import Tag from "../../components/tag.vue";
import { mapMutations, mapActions } from "vuex";
import { clickOutside } from "../../helpers/actions"
import SharePost from "../Feed/SharePost.vue";
export default {
  components: { Avatar, CommentPopUp, Tag, PostPopUp, SharePost },
  props: {
    post: {
      type: Object,
      default: {},
    },
    viewIcon: {
      type: Boolean,
      default: true,
    },
    saved: {
      type: Boolean,
      default: false,
    },
    redirect: {
      type: Boolean,
      default: false,
    },
    commentRedirect: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      user: null,
      university: "",
      reactions: {
        like: {},
        upvote: {},
        downvote: {},
      },
      comments: this.post.comments_count,
      likes: this.post.posts_likes_count,
      dislikes: this.post.posts_dislikes_count,
      isLiked: !!this.post.is_liked_by_user_count,
      isDisliked: !!this.post.is_disliked_by_user_count,
      isSaved: this.saved || !!this.post.saved_posts_count,
      showModal: false,
      update: 0,
      open: false,
      isCurrentUser: false,
      isCurrentUniversity: false,
      showPostModal: false,
      updatePost: 0,
      tutor: false,
      imageUrl: '',
      postImage: null,
      updateOptionPopUp: 0,
      openShareModal: false,
      updateShareModal: 0,
    };
  },
  mounted() {
    clickOutside(document.getElementById('controls'), () => {
      this.open = false;
    })
  },
  created() {
    this.user = this.post.user ?? this.$store.state.user;
    if (this.user.is_tutor == 1 || this.user.tutor) {
      this.tutor = true;
    }

    this.doesImageExist(this.post.post_attachment).then((isImage) => {
      if (isImage) this.postImage = this.post.post_attachment;
    })
    this.isCurrentUser = this.$store.state.profile?.user_id === this.post.user_id;
    this.isCurrentUniversity = this.$store.state.profile?.university_id === this.post.university_id || !!this.post.is_global;
  },

  methods: {
    ...mapMutations(["fireToast"]),
    ...mapActions(["completeQuest"]),

    optionsPopUp() {
      if (this.open) { this.open = false; this.updateOptionPopUp++; }
      else { this.open = true; this.updateOptionPopUp++; }
    },
    doesImageExist(url) {
      return new Promise((resolve) => {
        const img = new Image();

        img.src = url;
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
      })
    },

    setPost(postObject) {
      const { category_id, text, is_anonymous, post_attachment } = postObject;
      this.post.category_id = category_id;
      this.post.posts_text = text;
      this.post.is_anonymous = is_anonymous;
      this.post.post_attachment = post_attachment;
    },
    // allow only one of two types of reactions
    async assertMutualExclusion(type) {
      if (this.isLiked && this.isDisliked) {
        if (type === "upvote") await this.react("downvote");
        if (type === "downvote") await this.react("upvote");
      }
    },

    async react(type) {
      if (!this.isCurrentUniversity) return;
      let reactionId
      switch (type) {
        case "upvote":
          this.isLiked = !this.isLiked;
          if (this.isLiked) this.likes++;
          else this.likes--;
          reactionId = 1;
          break;
        case "downvote":
          this.isDisliked = !this.isDisliked;
          if (this.isDisliked) this.dislikes++;
          else this.dislikes--;
          reactionId = 2;
          break;
      }

      if ((!this.isLiked && type === "upvote") || (!this.isDisliked && type === "downvote")) await deleteLikeOrDislikePost(this.post.post_id, type === "upvote" ? 1 : 2);
      else {
        await this.assertMutualExclusion(type);
        await likeOrDislikePost(reactionId, this.post.post_id)
      }

    },
    comment() {
      this.showModal = true;
      this.update++;
    },
    redirectForComment() {
      if (this.commentRedirect) {
        this.showModal = true;
        this.update++;
      }
      else {
        this.$router.push(`/feed/post/${this.post.post_id}`);
      }
    },
    async save() {
      this.isSaved = !this.isSaved;
      if (this.isSaved)
        await savePost(this.post.post_id);
      else await removeSavedPost(this.post.post_id);
    },
    redirectToPostPage() {
      if (this.redirect) {
        this.$router.push(`/feed/post/${this.post.post_id}`);
      }

    },
    async report() {
      if (confirm("Are you sure you want to report this post?")) {
        await ReportPost(this.post.post_id);

        this.fireToast({
          text: `Post Reported!`,
          type: "confirmation",
        });
      }
    },
    async DeleteIt() {
      this.open = !open;
      if (confirm("Are you sure you want to delete this post?")) {
        await DeletePost(this.post.post_id);

        this.fireToast({
          text: `Post Deleted!`,
          type: "confirmation",
        });

        this.$router.replace("/").then(() => {
          this.$router.go();
        });
      }
    },
    postpopup() {
      this.showPostModal = true;
      this.updatePost++;
    },
    share() {
      this.openShareModal = true;
      this.updateShareModal++;
    }

  },
};
</script>
