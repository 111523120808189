import store from "../../../store";

export const createComment = async (comment) => {
  try {
    return await store.state.feedAPI.post("Comment/Create", comment);
  } catch (e) {
    console.log(e);
  }
};

export const editComment = async (comment) => {
  try {
    return await store.state.feedAPI.post("Comment/Edit", comment);
  } catch (e) {
    console.log(e);
  }
};

export const getCommentsbyPostId = async (id) => {
  try {
    return await store.state.feedAPI.get(`Comments/${id}`);
  } catch (e) {
    console.log(e);
  }
};

export const approveComment = async (id) => {
  return await store.state.feedAPI.post(`Comment/Approve`, { comment_id: id });
};

export const unAprroveComment = async (commentId) => {
  return await store.state.feedAPI.post(`Comment/Delete/Approve`, { comment_id: commentId });
};

export const deleteComment = async (id) => {
  return await store.state.feedAPI.post(`Comment/Delete`, { comment_id: id });
};

export const reportComment = async (id) => {
  return await store.state.feedAPI.post(`Comment/Report`, { comment_id: id });
};


