<template>
  <div class="relative pt-10">
    <filter-tag class=" absolute -top-1 -left-1 m-3 h-9 uppercase" :active="true">{{ _class.university.abbrev ??
        university
    }}
    </filter-tag>
    <div class="rounded-lg flex flex-col p-3 shrink-0 flex-1 shadow-lg box justify-end">
      <div class="flex flex-row items-end justify-between space-x-2 text-left">
        <div class="flex flex-col justify-end items-start space-y-1">
          <p class="text-orange">{{ _class.desc }}</p>
          <p class="text-black font-poppins font-medium leading-6 text-base">
            {{ _class.name }}
          </p>
        </div>
        <Button v-if="selectable" @click="this.$emit('select')">select</Button>
        <Button v-else shape="ghost" icon="arrow-right"
          @click="this.$router.push(`/results?class=${_class.desc}`)">view</Button>
      </div>
    </div>
  </div>
</template>

<script>
import FilterTag from "../Feed/FilterTag.vue";
import Button from "./Button.vue";
export default {
  props: {
    _class: {
      type: Object,
      required: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    university: String,
  },
  components: { Button, FilterTag },
  emits: ["select"],
};
</script>

<style scoped>
.box {
  box-shadow: -4px 5px 16px rgba(0, 0, 0, 0.1);
  min-width: 21rem !important;
  min-height: 6rem !important;
}
</style>
